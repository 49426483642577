import validate from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.7.4_eslint@9.11.1_sass@1.77.8_stylelint@16.9.0_typescript@5.6.2_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.7.4_eslint@9.11.1_sass@1.77.8_stylelint@16.9.0_typescript@5.6.2_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/middleware/auth.ts"),
  cgu: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/middleware/cgu.ts"),
  "skip-auth": () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/middleware/skip-auth.ts")
}