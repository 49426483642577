import { default as emailsGZYafkBr7gMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/emails.vue?macro=true";
import { default as integrationseY4N5G0VqrMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/integrations.vue?macro=true";
import { default as overviewZNsRakEtkKMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/overview.vue?macro=true";
import { default as indexZxf3SwI3L3Meta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsliY698Trp7Meta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as indexujInU3Gf5jMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/structures/index.vue?macro=true";
import { default as indexFNcdIbpgzcMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/users/[id]/index.vue?macro=true";
import { default as adddXsWIxJXKWMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/users/add.vue?macro=true";
import { default as indexLjugwuIpRrMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/users/index.vue?macro=true";
import { default as adminc3Xa5yyrZhMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin.vue?macro=true";
import { default as accountH2eZLWkGrsMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/account.vue?macro=true";
import { default as entouragegW0GBZajDcMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationsNRYBm0IqSrMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthrQsig871CKMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as infoIDuzWmdBttMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupationVqD0MljooFMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdpwzZlWIVZnMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printtG0a63ozKuMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as indexiUFfVSaOrWMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/index.vue?macro=true";
import { default as editxB33eGS8pYMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as edit2XyQuXqFcuMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as editPLg2p2Ki5pMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addbALOu1J5R0Meta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as editwgYUDwNgRWMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as addlQn1XL4zyeMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as editjqexnJ1oDYMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addz3k70tbYKGMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editE19juBPrCsMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as indexughoVntgUOMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/index.vue?macro=true";
import { default as addBNSXqxShRoMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/add.vue?macro=true";
import { default as historyZPKfdtvmTyMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/history.vue?macro=true";
import { default as printizSmHyvKZrMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as overviewnJb761PSOKMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/overview.vue?macro=true";
import { default as partnersO7Twfivm2eMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/partners.vue?macro=true";
import { default as statsiC72ZtseoZMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/stats.vue?macro=true";
import { default as indexCvJz3GXf0MMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/structure/index.vue?macro=true";
import { default as subjectsrsNURKoX8ZMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/structure/subjects.vue?macro=true";
import { default as usersIHo659AU1tMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/users.vue?macro=true";
import { default as appjHTkUehJ9RMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app.vue?macro=true";
import { default as adminV398ryS3BLMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/auth/admin.vue?macro=true";
import { default as loginvsfwcueuLoMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/auth/login.vue?macro=true";
import { default as logoutqdNMqzAtGqMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/auth/logout.vue?macro=true";
import { default as proconnectVcKwifolIBMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/auth/proconnect.vue?macro=true";
import { default as validate_45tokenHmkl1DVCI8Meta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/auth/validate-token.vue?macro=true";
import { default as managerYcxxmkceVLMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/cgu/manager.vue?macro=true";
import { default as userl80aX68zvNMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/cgu/user.vue?macro=true";
import { default as cguKgruOmW0LDMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/cgu.vue?macro=true";
import { default as declaration_45accessibiliteamQU2g2rUBMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45accountYKd69qZBpyMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/error/disabled-account.vue?macro=true";
import { default as unknown_45user36X7rFes5HMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/error/unknown-user.vue?macro=true";
import { default as index2wGLJs4FiCMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/index.vue?macro=true";
import { default as mentions_45legalesKbiC1gIfuTMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialiteiIvDbXSxFCMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/politique-confidentialite.vue?macro=true";
import { default as registerUncp0x3pfBMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/register.vue?macro=true";
import { default as statistiquesl6Gnr9sdtMMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/statistiques.vue?macro=true";
import { default as storiesKvBWxvRvaeMeta } from "/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminc3Xa5yyrZhMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/integrations.vue").then(m => m.default || m)
  },
  {
    name: overviewZNsRakEtkKMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overviewZNsRakEtkKMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/overview.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/structures/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/structures/[id]/subjects.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/structures/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/users/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: appjHTkUehJ9RMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: "app-account",
    path: "account",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/account.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/edit/entourage.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/edit/external-organisations.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/edit/health.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/edit/info.vue").then(m => m.default || m)
  },
  {
    name: occupationVqD0MljooFMeta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupationVqD0MljooFMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/edit/occupation.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/edit/tax-household.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printtG0a63ozKuMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: indexiUFfVSaOrWMeta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: indexiUFfVSaOrWMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/beneficiaries/index.vue").then(m => m.default || m)
  },
  {
    name: editxB33eGS8pYMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: editxB33eGS8pYMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/budget/edit.vue").then(m => m.default || m)
  },
  {
    name: edit2XyQuXqFcuMeta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: edit2XyQuXqFcuMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/expenses/[eid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/followups/[fid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/followups/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/help-requests/[subject]/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/help-requests/housing/add.vue").then(m => m.default || m)
  },
  {
    name: editE19juBPrCsMeta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editE19juBPrCsMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/income/[iid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/files/add.vue").then(m => m.default || m)
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/history.vue").then(m => m.default || m)
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printizSmHyvKZrMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/historyprint/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: overviewnJb761PSOKMeta?.name ?? "app-overview",
    path: "overview",
    meta: overviewnJb761PSOKMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/overview.vue").then(m => m.default || m)
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/partners.vue").then(m => m.default || m)
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/stats.vue").then(m => m.default || m)
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/structure/index.vue").then(m => m.default || m)
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/structure/subjects.vue").then(m => m.default || m)
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/app/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: adminV398ryS3BLMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/auth/admin.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginvsfwcueuLoMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutqdNMqzAtGqMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-proconnect",
    path: "/auth/proconnect",
    meta: proconnectVcKwifolIBMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/auth/proconnect.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45tokenHmkl1DVCI8Meta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/auth/validate-token.vue").then(m => m.default || m)
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cguKgruOmW0LDMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/cgu.vue").then(m => m.default || m),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/cgu/manager.vue").then(m => m.default || m)
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/cgu/user.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibiliteamQU2g2rUBMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/declaration-accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45accountYKd69qZBpyMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/error/disabled-account.vue").then(m => m.default || m)
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45user36X7rFes5HMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/error/unknown-user.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index2wGLJs4FiCMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legalesKbiC1gIfuTMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialiteiIvDbXSxFCMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/politique-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerUncp0x3pfBMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiquesl6Gnr9sdtMMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/statistiques.vue").then(m => m.default || m)
  },
  {
    name: "stories",
    path: "/stories",
    meta: storiesKvBWxvRvaeMeta || {},
    component: () => import("/build/79fc9426-04c4-40f3-a43d-7088391fe64c/pages/stories.vue").then(m => m.default || m)
  }
]